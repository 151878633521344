$(document).ready(function () {

    var currentQuestionNr = 0;
    var correctAnswers = 0;

    var $quizForm = $('#quiz-form');
    var $quizFragen = $('#quiz-fragen');
    var $quizOutro = $('#quiz-outro');
    var $quizQuestionAnswerRadioButtons = $('.quiz-question-buttons>div:first-child>input[type="radio"].question');

    var $buttonQuizRestart = $('#quiz-restart');
    var $buttonQuizCheck = $('#quiz-check');
    var $buttonQuizNext = $('#quiz-next');
    var $buttonQuizSubmit = $('#quiz-submit');

    $quizOutro.hide();
    showQuestion(0);
    resetQuestion(0);

    function scrollToFormAnchor() {
        window.scroll({
            top: $('#quiz-form').offset().top,
            left: 0,
            behavior: 'smooth'
        });
    }

    // check answer and display right/wrong
    $buttonQuizCheck.click(function() {

        var $currentQuestion = $('#question-'+currentQuestionNr);

        resetQuestion(currentQuestionNr);

        // check if an answer is selected
        if (!$currentQuestion.find('input:radio').is(':checked')) {
            $quizFragen.focus();
            $('.info-no-answer').show();
            return false;
        }

        var selectedAnswerString = $currentQuestion.find('.question:checked').val();

        $.ajax({
            url: '/index.php?eID=vmasterquizcheck',
            type: 'POST',
            contentType: "application/json",
            cache: false,
            data: '{"value":"'+selectedAnswerString+'"}',
            dataType: "json",
            success: function(response) {
                var selectedAnswerUid = selectedAnswerString.split('_')[1];

                if(response.correct === true) {
                    $currentQuestion.find('.answer-'+selectedAnswerUid).addClass('quiz-answer-right');
                    $currentQuestion.find('.answer-right').show();
                    correctAnswers++;
                    //console.log(correctAnswers);
                } else {
                    $currentQuestion.find('.answer-'+selectedAnswerUid).addClass('quiz-answer-wrong');
                    $currentQuestion.find('.answer-'+response.rightAnswerUid).addClass('quiz-answer-right');
                    $currentQuestion.find('.answer-'+response.rightAnswerUid).find('label.self-center').removeClass('bund-copy-text').addClass('bund-status-text-ok');
                    $currentQuestion.find('.answer-'+response.rightAnswerUid).find('input.question').removeClass('bund-forms-radio').addClass('bund-forms-radio-shouldbeok');
                    $currentQuestion.find('.answer-wrong').show();
                }
                $currentQuestion.find('.quiz-selected-answer').val(selectedAnswerUid);

                disableQuestion(currentQuestionNr);

                //scrollToFormAnchor();
            },
            error: function(error) {
                console.log(error);
            }
        });

        return false;
    });

    // go to next question
    $buttonQuizNext.click(function() {
        var nextQuestionNr = currentQuestionNr + 1;

        currentQuestionNr = nextQuestionNr;

        showQuestion(nextQuestionNr);
        resetQuestion(nextQuestionNr);
        $quizFragen.focus();
        //scrollToFormAnchor();

        return false;
    });

    // go to summary and display results
    $buttonQuizSubmit.click(function () {
        console.log(correctAnswers);

        var questionsTotal = $('.quiz-question').length;

        // check outro header for placeholders to insert correct and total answers
        var $outroHeader = $('#quiz-outro-header');
        $outroHeader.html($outroHeader.html().replace('%X', correctAnswers).replace('%Y', questionsTotal));

        // calculate summary data
        var percentage = Math.round((correctAnswers * 100) / questionsTotal);

        // show correct outro message, depending on percentage
        var $outroTexts = $('.quiz-outro-text');
        $outroTexts.hide();
        $outroTexts.each(function (i, el) {
            var $text = $(el);
            if (percentage >= $text.data('rangeFrom') && percentage <= $text.data('rangeTo')) {
                $text.show();

                // exit to only show the first message whose range matches (might overlap in edge cases)
                return false;
            }
        });

        $quizForm.hide();
        $quizOutro.show();
        $buttonQuizRestart.focus();

        return false;
    });

    // reset quiz
    $buttonQuizRestart.click(function (event) {

        event.preventDefault();

        currentQuestionNr = 0;
        correctAnswers = 0;
        $('#quiz-outro-header').html('%X von %Y Fragen richtig!');
        //console.log(correctAnswers);

        $quizOutro.hide();
        $quizForm.show();

        showQuestion(0);
        resetQuestion(0);

        resetRadioButtons();

        $quizFragen.focus();

        return false;
    });

    function showQuestion(nr) {
        $('#quiz-question-number').html(nr + 1);
        $('.quiz-question').hide();
        $('#question-'+nr).show();
    }

    function disableQuestion(nr) {
        $('#question-'+nr).find('.question:not(:checked)').attr('disabled', 'disabled');


        //Fehlerhafte Antworten markieren
        if($('#question-'+nr).find('.answer-wrong').is(':visible')){
            $('#question-'+nr).find('.question:checked').removeClass('bund-forms-radio').addClass('bund-forms-radio-checked-disabled-wrong');
            $('#question-'+nr).find('.question:checked').parent().find('label.self-center').removeClass('bund-copy-text').addClass('bund-status-text-fehler');
        }

        //Richtige Antworten markieren
        if($('#question-'+nr).find('.answer-right').is(':visible')){
            $('#question-'+nr).find('.question:checked').removeClass('bund-forms-radio').addClass('bund-forms-radio-checked-disabled-right');
            $('#question-'+nr).find('.question:checked').parent().find('label.self-center').removeClass('bund-copy-text').addClass('bund-status-text-ok');
        }

        $buttonQuizCheck.hide();

        if (parseInt(nr, 10) === $('.quiz-question').length - 1) {
            $buttonQuizSubmit.show();
            $buttonQuizSubmit.focus();
        } else {
            $buttonQuizNext.show();
            $buttonQuizNext.focus();
        }
    }

    function resetQuestion(nr) {
        var $question = $('#question-'+nr);
        $question.find('.info-no-answer').hide();
        $question.find('.answer-wrong').hide();
        $question.find('.answer-right').hide();
        $buttonQuizNext.hide();
        $buttonQuizSubmit.hide();
        $buttonQuizCheck.show();
    }

    function resetRadioButtons() {
        $( "div.quiz-question" ).each(function() {

            $(this).find('fieldset.quiz-question-buttons > div').removeClass('quiz-answer-right');
            $(this).find('fieldset.quiz-question-buttons > div').removeClass('quiz-answer-wrong');

            $(this).find('fieldset.quiz-question-buttons > div input.question').prop('checked', false);
            $(this).find('fieldset.quiz-question-buttons > div input.question').removeAttr('disabled');

            $(this).find('fieldset.quiz-question-buttons > div input.question').removeClass('bund-forms-radio-checked-disabled-wrong').addClass('bund-forms-radio');
            $(this).find('fieldset.quiz-question-buttons > div input.question').removeClass('bund-forms-radio-checked-disabled-right').addClass('bund-forms-radio');
            $(this).find('fieldset.quiz-question-buttons > div input.question').removeClass('bund-forms-radio-shouldbeok').addClass('bund-forms-radio');

            $(this).find('fieldset.quiz-question-buttons label.self-center').removeClass('bund-status-text-fehler').addClass('bund-copy-text');
            $(this).find('fieldset.quiz-question-buttons label.self-center').removeClass('bund-status-text-ok').addClass('bund-copy-text');
        });
    }
});
